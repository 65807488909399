import { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import GuardedRoute from "./components/guardedRoute/GuardedRoute";
import { appRoutes } from "../routes";
import { RoutesContext } from "./contexts/routes.context";
import moment from "moment";
import "moment/locale/sv";
import "moment/locale/en-au";
import CookieAlertComponent from "./components/cookieAlert/CookieAlertComponent";
import Header from "./components/header/header";
import authService from "./services/auth/auth.service";
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";
import dataLayerHelper from "./helpers/dataLayerHelper/dataLayerHelper";
import { UserRole } from "./models/dto/users/user.dto";
import useBrowserBackStack from "./hooks/browserBackStack";
import { BrowserHistoryContext } from "./contexts/browserHistory.context";

const DashboardComponent = lazy(() => import("./dashboard/DashboardComponent"));
const CmsPageComponent = lazy(() => import("./pages/cms/page/CmsPageComponent"));
const PagesComponent = lazy(() => import("./pages/PagesComponent"));

const AppComponent = () => {
    const [routes, setRoutes] = useState(appRoutes);
    const [showCookieAlert, setShowCookieAlert] = useState<boolean>(false);
    const [errorHappened, setErrorHappened] = useState<boolean>(false);
    const [allowFunctional, setAllowFunctional] = useState<boolean>(false);

    const { t, i18n } = useTranslation();
    const backStack = useBrowserBackStack();

    const baseRouteUrl = "/:locale(en|sv)?";
    const { locale } = useParams<{ locale: string }>();
    if (!locale) {
        window.location.href = window.location.pathname == "/undefined" ? "/sv" : "/sv" + window.location.pathname;
    }
    moment.locale(locale == "en" ? "en-au" : locale);
    var faq_slug = 'vanliga-fragor-2';
    var about_slug = 'om-bookabus-se';
    var contact_slug = 'kontakta-oss';
    if (i18n.language == 'en'){
        faq_slug = 'faq';
        about_slug = 'about';
        contact_slug = 'contact-us';
    }
    const updateRoutesByLanguage = (language: string, defaultRoutes: any) => {
        let newRoutes: any = {}
        Object.keys(routes).forEach((key) => {
            if (typeof routes[key] == "object") {
                newRoutes[key] = updateRouteProperties(routes[key], language);
            }
            else {
                newRoutes[key] = "/" + language + routes[key];
            }
        });
        setRoutes(newRoutes);
    }
    const updateRouteProperties = (routeObject: any, language: string) => {
        let properties = Object.keys(routeObject);
        let newRoutes: any = {};
        properties.forEach((key) => {
            if (typeof routeObject[key] == "object") {
                newRoutes[key] = updateRouteProperties(routeObject[key], language);
            }
            else if (routeObject[key]) {
                const pathParam = routeObject[key].toString().split("/").find((c: string) => c.startsWith(":"));
                if (pathParam) {
                    newRoutes[key] = "/" + language + routeObject[key].toString().replace(pathParam, "");
                }
                else {
                    newRoutes[key] = "/" + language + routeObject[key];
                }
            }
        });
        return newRoutes;
    }


    function getAllowFunctional() {
        if (localStorage.getItem("cookiesAllowed")) {
            var cookie = localStorage.getItem("cookiesAllowed");
            if (cookie && (cookie.toLowerCase().indexOf("all") > -1 || cookie.toLowerCase().indexOf("functional") > -1)) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        if (!localStorage.getItem("cookiesAllowed")) {
            setShowCookieAlert(true);
        } else {
            if (!Cookies.get("cookieconsent_value")) {
                Cookies.set('cookieconsent_value', JSON.stringify([]), { expires: 7 })
            }
        }
        setAllowFunctional(getAllowFunctional())
        i18n.changeLanguage(locale);
        updateRoutesByLanguage(locale, appRoutes);
    }, [])
    // @ts-ignore
    return <>

        {<RoutesContext.Provider value={{ routes: routes, setLanguage: (language: string) => { updateRoutesByLanguage(language, appRoutes) } }}>
            <BrowserHistoryContext.Provider value={{ backStack: backStack as any }}>


                <CookieAlertComponent onConfirm={(cookiesAllowed: string[]) => {
                    localStorage.setItem("cookiesAllowed", JSON.stringify(cookiesAllowed)); Cookies.set('cookieconsent_value', JSON.stringify(cookiesAllowed), { expires: 7 });
                    dataLayerHelper.push({
                        event: "cookie_consent_updated",
                        consents: {
                            analytics: cookiesAllowed.includes("Functional") ? 1 : 0,
                            marketing: cookiesAllowed.includes("Marketing") ? 1 : 0
                        }
                    });
                    setShowCookieAlert(false)
                }}
                    show={showCookieAlert} />
                <Suspense fallback={<div></div>}>

                    <Switch>

                        <GuardedRoute 
                            path={baseRouteUrl + appRoutes.dashboard.main.path}>
                            <DashboardComponent />
                        </GuardedRoute>
                        <Route path={baseRouteUrl + appRoutes.cms.page}>
                            <Header navMenuItems={authService.isAuthenticated() ? [{ link: routes.main, title: "Titles.Book" },
                            { link: routes.booking.list, title: "Titles.MyReservations" },
                            { link: routes.user.profile, title: "Titles.Profile" }] :
                                [{ link: routes.main, title: "Titles.Book" },
                                { link: routes.auth.register, title: "Titles.CreateAccount" },
                                { link: routes.auth.registerCompany, title: "Titles.RegisterCompany" }]}
                                hamburgerMenuItems={authService.isAuthenticated() ? [
                                    { title: t("Hints.RentABus"), link: "/" + i18n.language },
                                    ...(authService.isDealCustomerUser() ? [] : [{ title: t("Titles.MyReservations"), link: routes.booking.list, isInternal: true }]),
                                    { title: t("Titles.Profile"), link: routes.user.profile, isInternal: true },
                                    { title: t("Hints.FAQ"), link: "/" + i18n.language + "/page/" + faq_slug },
                                    { title: t("Hints.ContactUs"), link: "/" + i18n.language + "/page/" + contact_slug }
                                ] : [
                                    { title: t("Hints.RentABus"), link: "/" + i18n.language },
                                    { title: t("Hints.FAQ"), link: "/" + i18n.language + "/page/" + faq_slug },
                                    { title: t("Hints.AboutBB"), link: "/" + i18n.language + "/page/" + about_slug },
                                    { title: t("Hints.ContactUs"), link: "/" + i18n.language + "/page/" + contact_slug },
                                    { title: t("Titles.CreateAccount"), link: routes.auth.register, isInternal: true },
                                    { title: t("Titles.RegisterCompany"), link: routes.auth.registerCompany, isInternal: true },
                                ]}

                                loginUrl={routes.auth.login}></Header>
                            <CmsPageComponent />

                        </Route>

                        <GuardedRoute
                            notAllowedRoles={[
                                { name: UserRole.SystemAdmin, redirectRoute: routes.dashboard.admin.main.path },
                                { name: UserRole.SystemOwner, redirectRoute: routes.dashboard.admin.main.path },
                                { name: UserRole.CompanyAdmin, redirectRoute: routes.dashboard.company.main.path },
                                { name: UserRole.DealCustomerFinance, redirectRoute: routes.dashboard.dealOrg.main.path }
                            ]}
                            noAuthNeeded={true}
                            path={baseRouteUrl + appRoutes.main}>
                            <PagesComponent />
                        </GuardedRoute>

                    </Switch>
                </Suspense>
            </BrowserHistoryContext.Provider>

        </RoutesContext.Provider>}

    </>

}
export default AppComponent;